import React, { useState, useCallback, useEffect } from 'react';

import CloseIcon from 'assets/close-drawer.svg';
import { toaster, ToasterType } from 'components/Toaster';
import { GqlTeacherProfile, TeacherPatch } from 'model/profiles';
import { updateTeacherProfile } from 'network/Profile';
import { diffChangedProps, ddlog } from 'utils/miscellaneous';

import EditFormTeacher from './edit-form-teacher.component';
import { ViewContent } from './helpers';
import { TeacherViewWrapper, CloseWrapper } from './styledComponents';

export interface InnerProps {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  isOpen: boolean;
  profile: GqlTeacherProfile;
  triggerTeacherCb?: (evt?: MouseEvent) => void;
  onUpdateProfile(newProfile: TeacherPatch): void;
}

const TeacherViewOfProfile = ({ profile, isOpen, onUpdateProfile, triggerTeacherCb }: InnerProps) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const switchToEditMode = useCallback(() => setEditMode(true), []);
  const switchToViewMode = useCallback(() => setEditMode(false), []);

  const onSave = useCallback(
    async (newProfile: TeacherPatch) => {
      setDisableButton(true);
      try {
        await updateTeacherProfile(
          profile.id!,
          diffChangedProps(newProfile, profile as Record<keyof GqlTeacherProfile, unknown>),
        );
        onUpdateProfile(newProfile);
        switchToViewMode();
        toaster('Profile saved.', ToasterType.SUCCESS);
      } catch (error) {
        toaster('Saving profile has failed.', ToasterType.ERROR);
        ddlog.error('Saving profile has failed in SideBar TeacherViewOfProfile', { error });
      } finally {
        setDisableButton(false);
      }
    },
    [onUpdateProfile, profile, switchToViewMode],
  );

  useEffect(() => {
    if (!isOpen) {
      setEditMode(false);
    }
  }, [isOpen]);

  return !editMode ? (
    <TeacherViewWrapper>
      <CloseWrapper onClick={() => (triggerTeacherCb ? triggerTeacherCb() : null)}>
        <CloseIcon />
      </CloseWrapper>
      <ViewContent profile={profile} switchToEditMode={switchToEditMode} />
    </TeacherViewWrapper>
  ) : (
    <EditFormTeacher
      switchToViewMode={switchToViewMode}
      profile={profile}
      onSave={onSave}
      disableButton={disableButton}
    />
  );
};

export default TeacherViewOfProfile;
