import bookingsClassEntry from 'cypress/fixtures/teacherfirst/dashboard/generic/bookings-class-entry.json';
import { HttpResponse, http } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-3/class-entry-info`, () =>
    HttpResponse.json(bookingsClassEntry),
  ),
];
