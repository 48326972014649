import { useQuery, NetworkStatus } from '@apollo/client';

import { useSchoolContext } from 'context/School/School';
import { EVCLocation } from 'model/misc';
import { GqlTeacherProfile, GqlTeacherSearch } from 'model/profiles';
import makeRequest from 'network/helpers/makeRequest';

import { IGQLTeacherResponse, IGQLTeachersResponse, TEACHER_GQL, TEACHER_SEARCH_GQL } from './graphql/Teachers';

export interface IPagination {
  size: number;
  num: number;
}
export interface ITeacherFilter {
  centers?: string[];
  teacherSkillIds?: string[];
  languages?: string[];
  interests?: string[];
  departmentIds?: string[];
  roles?: string[];
  freeText?: string;
  statuses?: string[];
  sort?: string;
  userId?: string;
}

export interface ITeacherSearch {
  pagination?: IPagination;
  filters?: ITeacherFilter;
  skip?: boolean; // used for debouncing graphql queries
}

export const useQuerySearchTeachers = ({
  pagination = { size: 24, num: 1 },
  filters = {},
  skip = false,
}: ITeacherSearch) => {
  const { schoolId } = useSchoolContext();
  const { loading, error, data, refetch, networkStatus } = useQuery<IGQLTeachersResponse<GqlTeacherSearch>>(
    TEACHER_SEARCH_GQL,
    {
      variables: {
        filter: {
          centers: nullIfEmpty(filters.centers),
          skillIds: nullIfEmpty(filters.teacherSkillIds),
          languages: nullIfEmpty(filters.languages),
          interests: nullIfEmpty(filters.interests),
          departmentIds: nullIfEmpty(filters.departmentIds),
          teacherStatus: nullIfEmpty(filters.statuses),
          roles: nullIfEmpty(filters.roles),
          freeText: filters.freeText,
          userId: filters.userId,
          sort: filters.sort,
          schoolId,
        },
        pagination,
      },
      skip,
    },
  );

  return {
    loading: loading || networkStatus === NetworkStatus.refetch || skip,
    error,
    data: data?.teachers.edges || [],
    pagination: {
      ...data?.teachers.pageInfo,
    },
    refetch,
  };
};

export const useTeacherProfile = (teacherId?: string) => {
  const { data, ...rest } = useQuery<IGQLTeacherResponse<GqlTeacherProfile>>(TEACHER_GQL, {
    variables: {
      id: teacherId,
    },
    errorPolicy: 'ignore',
    skip: !teacherId,
  });

  return {
    teacher: data?.teacher,
    ...rest,
  };
};

export const updateEvcProxy = (teacherId: string, selectedEvcProxy: string) =>
  makeRequest({
    pathname: `evc/teacher-settings/${teacherId}`,
    method: 'PUT',
    body: {
      evcChinaTunnel: selectedEvcProxy,
      evcTunnels: [
        {
          location: EVCLocation.SH, // TODO: Currently UI only supports updating SH tunnel
          id: selectedEvcProxy,
        },
      ],
    },
  });

// nullIfEmpty returns the arr argument if the array is not empty, otherwise it returns null
export function nullIfEmpty<T>(arr: T[] | undefined): T[] | null {
  return arr?.length ? arr : null;
}
