import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import { ScreenWrapper } from 'components/sharedStyles';
import { getClassroomPath } from 'config/internalUrlPaths';
import { TF_PAGES } from 'config/pagesTitle';
import { useTeacherContext } from 'context/Profile';
import { useFetchBookingById } from 'network/Bookings';
import { IEntryResponse, IStudentGLJoin, loadStudentJoinDetails, useTeacherJoinStatus } from 'network/Lobby';
import { useInterval } from 'utils/hooks/useInterval';
import { getNStudent } from 'utils/testing/students';

import Loading from './components/Loading';
import QueueClosed from './components/QueueClosed';
import { ClassInfo, ClassInfoHeader, LobbyWrapper, StudentEnterClassButton } from './styledComponents';

interface IMatchParams {
  bookingId: string;
}

export const LobbyComponent = (props: RouteComponentProps<IMatchParams>) => {
  const bookingId = props.match.params.bookingId;
  const history = useHistory();
  const { id: teacherId } = useTeacherContext()?.selectedTeacher;
  const [teacherJoinRequestId, setTeacherJoinRequestId] = useState<string | null>(null);
  const { booking } = useFetchBookingById(bookingId);
  const { data: teacherJoinDetails, error } = useTeacherJoinStatus(bookingId, teacherJoinRequestId);
  const queueClosed = useMemo(() => {
    const failedStatus = teacherJoinDetails?.state === 'failed';
    return (
      error?.message === 'teacher entry period has ended' ||
      error?.message === 'the session has ended already' ||
      failedStatus
    );
  }, [error, teacherJoinDetails?.state]);
  const showLoading = !queueClosed && (!teacherJoinDetails || teacherJoinDetails?.state === 'waiting');

  useEffect(() => {
    if (teacherJoinDetails?.id) {
      setTeacherJoinRequestId(teacherJoinDetails.id);
    }
  }, [teacherJoinDetails]);

  useEffect(() => {
    const showClassroom =
      !queueClosed && (teacherJoinDetails?.state === 'in_progress' || teacherJoinDetails?.state === 'completed');

    if (showClassroom) {
      history.push(getClassroomPath(bookingId, teacherId));
    }
  }, [bookingId, history, queueClosed, teacherId, teacherJoinDetails?.state]);

  return (
    <LobbyWrapper>
      <Helmet>
        <title>{TF_PAGES.Lobby}</title>
      </Helmet>
      <ClassInfo data-qa="class-info">
        <ClassInfoHeader>Class info</ClassInfoHeader>
        <div>{booking?.lessonDefinitionId}</div>
        <div>
          {booking?.start.format('HH:mm')} - {booking?.end.format('HH:mm')}
        </div>
      </ClassInfo>
      {queueClosed && <QueueClosed />}
      {showLoading && <Loading bookingStart={booking?.start} />}
    </LobbyWrapper>
  );
};

export const StudentEnterClassRenderer = (active: boolean, entryUrl: string | undefined) => (
  <StudentEnterClassButton
    as={Link}
    to={{ pathname: entryUrl }}
    target="_blank"
    rel="noreferrer noopener"
    disabled={!active || !entryUrl}
  >
    Enter class
  </StudentEnterClassButton>
);

export const StudentLobbyComponent = () => {
  const query = new URLSearchParams(useLocation().search);
  const defaultStudent = getNStudent(1)[0];
  const [studentJoinDetails, setStudentJoinDetails] = useState<IEntryResponse>();

  const payload: IStudentGLJoin = {
    id: query.get('id') ?? defaultStudent.id,
    name: query.get('name') ?? defaultStudent.name,
    level: query.get('level') ?? 'beginner',
    acrType: query.get('acrType') ?? 'standard',
    contentId: query.get('contentId') ?? '',
    lessonDefId: query.get('lessonDefId') ?? '',
    start: query.get('start') ?? '',
  };

  const fetchStudentLoadDetails = () => {
    loadStudentJoinDetails(payload).then((response) => setStudentJoinDetails(response));
  };

  useInterval(() => fetchStudentLoadDetails(), studentJoinDetails ? 0 : 3e3);

  return (
    <ScreenWrapper>
      {!payload.contentId && <p>please set contentId in query string</p>}
      {!payload.lessonDefId && <p>please set lessonDefId in query string</p>}
      {!payload.start && <p>please set start in query string</p>}
      <h2>{!studentJoinDetails && 'Waiting for a teacher...'}</h2>
      {studentJoinDetails && StudentEnterClassRenderer(true, studentJoinDetails!.evc.webUrl)}
    </ScreenWrapper>
  );
};
