import Schools from 'cypress/fixtures/teacherfirst/common/schools.json';
import student1 from 'cypress/fixtures/teacherfirst/common/student.json';
import student2 from 'cypress/fixtures/teacherfirst/common/student2.json';
import student3 from 'cypress/fixtures/teacherfirst/common/student3.json';
import Teacher from 'cypress/fixtures/teacherfirst/common/teacher.json';
import Teachers from 'cypress/fixtures/teacherfirst/common/teachers.json';
import user from 'cypress/fixtures/teacherfirst/common/user.json';
import lessonDefinitionsCP20USEN from 'cypress/fixtures/teacherfirst/lessonDefinitions/CP20-US-EN.json';
import lessonDefinitionsGL45USEN from 'cypress/fixtures/teacherfirst/lessonDefinitions/GL45-US-EN.json';
import lessonDefinitionsGL45USES from 'cypress/fixtures/teacherfirst/lessonDefinitions/GL45-US-ES.json';
import lessonDefinitionsHC40USEN from 'cypress/fixtures/teacherfirst/lessonDefinitions/HC40-US-EN.json';
import lessonDefinitionsPL20USEN from 'cypress/fixtures/teacherfirst/lessonDefinitions/PL20-US-EN.json';
import lessonDefinitionsPL40CNEN from 'cypress/fixtures/teacherfirst/lessonDefinitions/PL40-CN-EN.json';
import lessonDefinitionsPL40EN from 'cypress/fixtures/teacherfirst/lessonDefinitions/PL40-EN.json';
import lessonDefinitionsPL40USEN from 'cypress/fixtures/teacherfirst/lessonDefinitions/PL40-US-EN.json';
import lessonDefinitionsPL40USES from 'cypress/fixtures/teacherfirst/lessonDefinitions/PL40-US-ES.json';
import { HttpResponse, graphql, http } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/users/me`, () => HttpResponse.json(user)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/GL45-US-EN`, () =>
    HttpResponse.json(lessonDefinitionsGL45USEN),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/PL40-CN-EN`, () =>
    HttpResponse.json(lessonDefinitionsPL40CNEN),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/PL40-US-EN`, () =>
    HttpResponse.json(lessonDefinitionsPL40USEN),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/PL40-US-ES`, () =>
    HttpResponse.json(lessonDefinitionsPL40USES),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/PL20-US-EN`, () =>
    HttpResponse.json(lessonDefinitionsPL20USEN),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/HC40-US-EN`, () =>
    HttpResponse.json(lessonDefinitionsHC40USEN),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/CP20-US-EN`, () =>
    HttpResponse.json(lessonDefinitionsCP20USEN),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/GL45-US-ES`, () =>
    HttpResponse.json(lessonDefinitionsGL45USES),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions/PL40EN`, () => HttpResponse.json(lessonDefinitionsPL40EN)),

  http.get(`${env.TF_SVCGATEWAY_URL}/v1/students/efpro/${student1.externalId}`, () => HttpResponse.json(student1)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/students/efpro/${student2.externalId}`, () => HttpResponse.json(student2)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/students/efpro/${student3.externalId}`, () => HttpResponse.json(student3)),

  graphql.query('Teachers', () => HttpResponse.json(Teachers)),
  graphql.query('Teacher', () => HttpResponse.json(Teacher)),
  graphql.query('Schools', () => HttpResponse.json(Schools)),
];
