import ClassRecordsFiltersCenterStatus from 'cypress/fixtures/class-records/data/class-records-filters-center-status.json';
import ClassRecordsFiltersLessonDefinitions from 'cypress/fixtures/class-records/data/class-records-filters-lesson-definitions.json';
import ClassRecordsFiltersPaytype from 'cypress/fixtures/class-records/data/class-records-filters-paytype.json';
import ClassRecordsSummaryAllGroups from 'cypress/fixtures/class-records/data/class-records-summary-all-groups.json';
import ClassRecords from 'cypress/fixtures/class-records/data/class-records.json';
import LessonDefinitions from 'cypress/fixtures/lessonDefinitions.json';
import AcrHistory from 'cypress/fixtures/teacherfirst/class-prep/acr-history.json';
import BookingPL1 from 'cypress/fixtures/teacherfirst/class-prep/booking-id-1.json';
import BookingPL2 from 'cypress/fixtures/teacherfirst/class-prep/booking-id-2.json';
import BookingPL5 from 'cypress/fixtures/teacherfirst/class-prep/booking-id-5.json';
import bookingPL from 'cypress/fixtures/teacherfirst/class-prep/bookingPL.json';
import EvcTopic201 from 'cypress/fixtures/teacherfirst/class-prep/evc-topics/201.json';
import EvcTopic203 from 'cypress/fixtures/teacherfirst/class-prep/evc-topics/203.json';
import EvcTopic204 from 'cypress/fixtures/teacherfirst/class-prep/evc-topics/204.json';
import EvcTopic205 from 'cypress/fixtures/teacherfirst/class-prep/evc-topics/205.json';
import EvcTopic489 from 'cypress/fixtures/teacherfirst/class-prep/evc-topics/489.json';
import bookingGL from 'cypress/fixtures/teacherfirst/class-prep/group-lesson-booking.json';
import Journey from 'cypress/fixtures/teacherfirst/class-prep/journey.json';
import StudentBio from 'cypress/fixtures/teacherfirst/class-prep/student-bio.json';
import GroupLessonTopics from 'cypress/fixtures/teacherfirst/class-prep/topics.json';
import Teacher from 'cypress/fixtures/teachers/teacher.json';
import { HttpResponse, http } from 'msw';

import env from 'config/env';

export const genericHandlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/lesson-definitions`, () => HttpResponse.json(LessonDefinitions)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/teachers/teacher-id`, () => HttpResponse.json(Teacher)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-1`, () => HttpResponse.json(BookingPL1)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-2`, () => HttpResponse.json(BookingPL2)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-3`, () => HttpResponse.json(bookingPL)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-5`, () => HttpResponse.json(BookingPL5)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-6`, () => HttpResponse.json(bookingGL)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/group-lessons/topics`, () => HttpResponse.json(GroupLessonTopics)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/f2b214c9-979a-4ad6-97f1-96e21472a2f5/acr-history`, () =>
    HttpResponse.json(AcrHistory),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/students/elive/43e330ee-cc72-4251-bb57-8de24a9f1dfa/journey`, () =>
    HttpResponse.json(Journey),
  ),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/evc-topic-contents/gl/201`, () => HttpResponse.json(EvcTopic201)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/evc-topic-contents/gl/203`, () => HttpResponse.json(EvcTopic203)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/evc-topic-contents/gl/204`, () => HttpResponse.json(EvcTopic204)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/evc-topic-contents/gl/205`, () => HttpResponse.json(EvcTopic205)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/evc-topic-contents/gl/489`, () => HttpResponse.json(EvcTopic489)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/student-bio`, () => HttpResponse.json(StudentBio)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v2/class-records-summary`, () => HttpResponse.json(ClassRecordsSummaryAllGroups)),
  http.get(`${env.TF_SVCGATEWAY_URL}/v2/class-records-filters`, ({ request }) => {
    const url = new URL(request.url);
    const property = url.searchParams.get('property');

    if (!property) {
      return new HttpResponse(null, { status: 404 });
    }

    switch (property) {
      case 'payType':
        return HttpResponse.json(ClassRecordsFiltersPaytype);
      case 'lessonDefinitionId':
        return HttpResponse.json(ClassRecordsFiltersLessonDefinitions);
      case 'centerStatus':
        return HttpResponse.json(ClassRecordsFiltersCenterStatus);

      default:
        return new HttpResponse(null, { status: 404 });
    }
  }),
  http.get(`${env.TF_SVCGATEWAY_URL}/v2/class-records`, () => HttpResponse.json(ClassRecords)),
];
