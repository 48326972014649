import React, { Fragment, Dispatch, SetStateAction, useCallback, useState } from 'react';

import LoadingMessage from 'components/Loading';
import { useTeacherContext } from 'context/Profile';
import { GqlTeacherProfile, TeacherPatch } from 'model/profiles';

import { ExpandedContainer, SubNavButton, SubNavigation, SubNavText, School } from './styledComponents';
import TeacherViewOfProfile from './teacher-view-of-profile.component';

interface Props {
  teacher: GqlTeacherProfile;
  school?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSave?(teacher: TeacherPatch): Promise<void>;
}

const Profile = ({ teacher, school, onSave, isOpen, setIsOpen }: Props) => {
  const [activeSubNav, setActiveSubNav] = useState<string>('profile');
  const refetch = useTeacherContext().refetch;

  const onUpdateProfile = useCallback(
    async (newProfile: TeacherPatch) => {
      await onSave?.(newProfile);
      refetch?.();
    },
    [onSave, refetch],
  );

  const triggerTeacherCb = useCallback(
    (evt?: MouseEvent) => {
      evt?.preventDefault();
      evt?.stopPropagation();
      // handles removal of scroll on the body and paddingRight makes it seamless
      // uses same interaction as when modals open
      document.body.style.overflow = isOpen ? '' : 'hidden';
      document.body.style.paddingRight = isOpen ? '' : '5px';
      setActiveSubNav('profile');
      setIsOpen(!isOpen);
    },
    [isOpen, setIsOpen],
  );

  return (
    <Fragment>
      <SubNavigation>
        <SubNavButton isActive={activeSubNav === 'profile'} onClick={() => setActiveSubNav('profile')}>
          <SubNavText>Profile</SubNavText>
        </SubNavButton>
      </SubNavigation>
      <School>{school}</School>
      {activeSubNav === 'profile' &&
        (!teacher ? (
          <LoadingMessage />
        ) : (
          <ExpandedContainer>
            <TeacherViewOfProfile
              isOpen={isOpen}
              onUpdateProfile={onUpdateProfile}
              profile={teacher}
              triggerTeacherCb={triggerTeacherCb}
            />
          </ExpandedContainer>
        ))}
    </Fragment>
  );
};

export default Profile;
