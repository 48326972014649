import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { Loading } from 'components/Loading';
import { UNAUTHORIZED_USER_PATH } from 'config/internalUrlPaths';
import useUserContext from 'context/Profile/UserProfile';
import { GqlTeacherProfile, Permissions, Resources } from 'model/profiles';
import { useTeacherProfile } from 'network/Teachers';
import useLocalForageState from 'utils/hooks/useLocalForageState';

export interface ITeacherContext {
  selectedTeacher: GqlTeacherProfile;
  availableTeacherIDs: string[];
  setTeacher: (teacherID: string) => Promise<void>;
  refetch?: ReturnType<typeof useTeacherProfile>['refetch'];
}

export const TeacherContext = createContext<ITeacherContext>({} as ITeacherContext);

interface Props {
  children: ReactNode;
}

export const ProvideTeacher = (props: Props) => {
  const { user } = useUserContext();
  const teacherIDs = useMemo(
    () =>
      user.permissions
        .filter(
          (permission) =>
            permission.resourceType === Resources.TEACHER && permission.permission === Permissions.VIEW_TEACHERS_PORTAL,
        )
        .map((permission) => permission.resourceId),
    [user.permissions],
  );
  const [selectedTeacherId, setSelectedTeacherIdAsync] = useLocalForageState(
    'selected_teacher_id',
    teacherIDs.find(() => true),
  );

  const { teacher: teacherProfile, refetch } = useTeacherProfile(selectedTeacherId);
  if (!teacherIDs.length) {
    return <Redirect to={UNAUTHORIZED_USER_PATH} />;
  }

  if (!teacherProfile) {
    return <Loading />;
  }

  return (
    <TeacherContext.Provider
      value={{
        selectedTeacher: teacherProfile,
        availableTeacherIDs: teacherIDs,
        setTeacher: setSelectedTeacherIdAsync,
        refetch,
      }}
    >
      {props.children}
    </TeacherContext.Provider>
  );
};

const useTeacherContext = () => useContext(TeacherContext);

export default useTeacherContext;
