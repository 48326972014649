import React, { CSSProperties, useMemo } from 'react';
import ReactDOM from 'react-dom';

import useWindowSize from 'utils/hooks/useWindowSize';
import { getModalPortalParent } from 'utils/window';

import Modal from './Modal';
import { ModalContentWrapper } from './modal.styled';

interface IModalProps {
  onClose: () => void;
  callback: () => void;
  portal: HTMLElement;
  message: React.ReactNode;
  title?: string;
  height?: CSSProperties['height'];
  primaryButtonLabel?: string;
  dataqa?: string;
}

const AreYouSureModal = ({
  onClose,
  callback,
  portal,
  message,
  title,
  height = 'auto',
  primaryButtonLabel = 'Confirm',
  dataqa,
}: IModalProps) => {
  const { mobile } = useWindowSize();

  return ReactDOM.createPortal(
    <Modal
      open
      title={title || 'Are you sure?'}
      onClose={onClose}
      primaryButtonLabel={primaryButtonLabel}
      onPrimaryClicked={() => {
        callback();
        onClose();
      }}
      secondaryButtonLabel="Cancel"
      onSecondaryClicked={onClose}
      minWidth={400}
      style={{
        height,
        borderRadius: '4px',
        maxWidth: 400,
      }}
      marginTop={10}
      dataqa={dataqa}
    >
      <ModalContentWrapper responsive={mobile}>
        <div style={{ margin: '16px 0', textAlign: 'center' }}>{message}</div>
      </ModalContentWrapper>
    </Modal>,
    portal!,
  );
};

const AreYouSureModalHandler = ({
  onClose: onCloseCallback,
  isOpen,
  callback,
  message,
  title,
  height,
  primaryButtonLabel,
  dataqa,
}: {
  onClose: () => void;
  isOpen: boolean;
  callback: () => void;
  message: React.ReactNode;
  title?: string;
  height?: CSSProperties['height'];
  primaryButtonLabel?: string;
  dataqa?: string;
}) => {
  const portal = useMemo(() => getModalPortalParent(), []);

  return isOpen ? (
    <AreYouSureModal
      onClose={onCloseCallback}
      portal={portal!}
      callback={callback}
      message={message}
      title={title}
      height={height}
      primaryButtonLabel={primaryButtonLabel}
      dataqa={dataqa}
    />
  ) : null;
};

export default AreYouSureModalHandler;
