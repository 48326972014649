import React, { Fragment } from 'react';

import OpSearch from 'assets/searchIllustration/op_search.svg';
import TfSearch from 'assets/searchIllustration/tf_search.svg';

import { ErrorWrapper } from './styledComponents';

interface Props {
  loading: boolean;
  page: number;
  children?: React.ReactNode; // children for loading state
  additionalMessage?: string;
  op?: boolean;
}

const LoadingOrError = ({ loading, page, children, additionalMessage, op }: Props) =>
  !loading ? (
    <ErrorWrapper>
      {op ? <OpSearch /> : <TfSearch />}
      <br />
      {page > 100 ? (
        <Fragment key="refine-search">
          <p>Please refine your search</p>
        </Fragment>
      ) : (
        <Fragment key="no-results">
          <p>No results found</p>
          {additionalMessage && <p style={{ marginTop: 4 }}>{additionalMessage}</p>}
        </Fragment>
      )}
    </ErrorWrapper>
  ) : (
    <Fragment>{children}</Fragment>
  );

export default LoadingOrError;
