import { useEffect, useState, useCallback } from 'react';

import localForage from 'utils/localForage';
import LFKeys from 'utils/localForageKeys';
import { ddlog } from 'utils/miscellaneous';

const useLocalForageState = <K extends keyof LFKeys>(key: K, initialValue?: LFKeys[K]) => {
  const [state, setState] = useState<LFKeys[K] | undefined>(initialValue);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setStateFromLocalForage = async () => {
      try {
        const localForageValue = await localForage.getItem<K>(key);
        if (localForageValue) {
          setState(localForageValue);
        }
      } catch (error: unknown) {
        ddlog.error('Error setting state from localForage', error as Error);
      }

      setLoading(false);
    };

    setStateFromLocalForage();
  }, [key]);

  const setAsyncState = useCallback(
    async (value: LFKeys[K]) => {
      setState(value);
      await localForage.setItem(key, value);
    },
    [key],
  );

  return [state, setAsyncState, loading] as const;
};

export default useLocalForageState;
