import React, { Fragment, useState, useCallback } from 'react';

import { SecondaryButton, PrimaryButton } from 'components/Button';
import Icon, { IconUnion } from 'components/Icon';
import MultiselectDropdown from 'components/MultiselectDropdown';
import {
  PersonalInterest,
  allPersonalInterests,
  personalInterestsIcons,
  allPersonalInterestsLabels,
} from 'config/teacherProfiles';
import { GqlTeacherProfile, TeacherPatch } from 'model/profiles';
import { useLanguageOptions } from 'network/Profile';
import { useTeacherPermissions } from 'utils/hooks/usePermissions';
import useSkillsMap from 'utils/hooks/useSkillOptions';
import { getEnglishFullLangName } from 'utils/miscellaneous';

import { RenderBasics, RenderLabeledValue, RenderList } from './helpers';
import { useField } from './profile.utils';
import {
  TeacherEditWrapper,
  Hr,
  Label,
  TileContainer,
  Tile,
  TileText,
  FieldContainer,
  ButtonWrapper,
} from './styledComponents';

const EditFormTeacher = ({
  profile,
  onSave,
  switchToViewMode,
  disableButton,
}: {
  profile: GqlTeacherProfile;
  onSave: (profile: TeacherPatch) => Promise<void>;
  switchToViewMode?: () => void;
  disableButton?: boolean;
}) => {
  const [unsavedPersonalInterests, setUnsavedPersonalInterests] = useState<PersonalInterest[]>(
    profile.personalInterests || [],
  );
  const skills = useSkillsMap();

  const { permissions } = useTeacherPermissions();
  const languageOptions = useLanguageOptions();

  const togglePersonalInterest = useCallback(
    (label: PersonalInterest) => {
      if (unsavedPersonalInterests.includes(label)) {
        setUnsavedPersonalInterests(unsavedPersonalInterests.filter((pi) => pi !== label));
      } else {
        setUnsavedPersonalInterests([...unsavedPersonalInterests, label]);
      }
    },
    [unsavedPersonalInterests],
  );

  const {
    value: selectedLangs,
    isError: isLanguagesError,
    isPristine: isLanguagesPristine,
    onChange: onLanguagesChange,
  } = useField<string[]>(profile.instructionLanguages);

  const onSaveInner = useCallback(
    () =>
      onSave({
        personalInterests: unsavedPersonalInterests,
        instructionLanguages: selectedLangs || [],
      } as TeacherPatch),
    [onSave, selectedLangs, unsavedPersonalInterests],
  );

  return (
    <TeacherEditWrapper editMode>
      <p style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 24 }}>Edit Profile</p>
      <RenderBasics profile={profile} />
      <Hr />
      <RenderLabeledValue label="Email" value={profile.email} />
      <Fragment>
        <Label>Personal Interests</Label>
        <TileContainer>
          {allPersonalInterests.map((piId) => (
            <Tile
              key={piId}
              isSelected={unsavedPersonalInterests.includes(piId)}
              disabled={!permissions.teacher.EditTeacherPersonalInterests()}
              onClick={() => togglePersonalInterest(piId)}
              data-name={piId}
            >
              <Icon icon={personalInterestsIcons[piId] as IconUnion} key={personalInterestsIcons[piId]} size={20} />
              <TileText>{allPersonalInterestsLabels[piId]}</TileText>
            </Tile>
          ))}
        </TileContainer>
      </Fragment>
      <RenderList
        label="Skills"
        list={profile?.teacherSkills.map((skill) => skill.id) || []}
        transformer={(el) => skills.get(el)?.description || ''}
      />
      {permissions.teacher.EditTeacherInstructionLanguages() ? (
        <FieldContainer showError={!isLanguagesPristine && isLanguagesError} style={{ width: 230 }}>
          <Label>Spoken Languages</Label>
          <MultiselectDropdown
            name="language"
            id="langs"
            options={languageOptions}
            size={16}
            disabled={!permissions.teacher.EditTeacherInstructionLanguages()}
            onChange={(_, newVals) => onLanguagesChange(newVals)}
            values={selectedLangs || []}
          />
        </FieldContainer>
      ) : (
        <RenderList label="Spoken Languages" list={profile.instructionLanguages} transformer={getEnglishFullLangName} />
      )}

      <ButtonWrapper>
        {switchToViewMode && (
          <SecondaryButton style={{ marginRight: 8 }} onClick={() => switchToViewMode()}>
            Cancel
          </SecondaryButton>
        )}
        <PrimaryButton disabled={disableButton} onClick={onSaveInner}>
          Save changes
        </PrimaryButton>
      </ButtonWrapper>
    </TeacherEditWrapper>
  );
};

export default EditFormTeacher;
