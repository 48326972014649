import { env } from 'process';

import mockStorageData from 'cypress/fixtures/teacherfirst/common/storage.json';
import { useCallback, useState, useEffect } from 'react';

export const useLocalStorage = <T>(key: string, defaultValue: T) =>
  useGenericStorage(key, defaultValue, window.localStorage);

export const useSessionStorage = <T>(key: string, defaultValue: T) =>
  useGenericStorage(key, defaultValue, window.sessionStorage);

const isMockedMode = env.MOCKED;

export const useStorage = <T>(key: string, defaultValue: T) => {
  const storage = isMockedMode ? staticStorage : window.localStorage;
  return useGenericStorage(key, defaultValue, storage);
};

let staticMockStorage: Record<string, string> = { ...mockStorageData };

const staticStorage: Storage = {
  getItem(key: string): string | null {
    return staticMockStorage.hasOwnProperty(key) ? staticMockStorage[key] : null;
  },
  setItem(key: string, value: string): void {
    staticMockStorage[key] = value;
  },
  removeItem(key: string): void {
    delete staticMockStorage[key];
  },
  clear(): void {
    staticMockStorage = {};
  },
  key(index: number): string | null {
    const keys = Object.keys(staticMockStorage);
    return keys[index] ?? null;
  },
  get length(): number {
    return Object.keys(staticMockStorage).length;
  },
};

const useGenericStorage = <T>(key: string, defaultValue: T, storageObject: Storage) => {
  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(key);
    if (jsonValue != null) {
      return JSON.parse(jsonValue);
    }

    return defaultValue;
  });

  useEffect(() => {
    if (value === undefined) {
      return storageObject.removeItem(key);
    }
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
};
