import { themes } from '@efcloud/ef-studio-fe-tools';
import { SimplePaletteColorOptions } from '@mui/material';
import { lighten, rgba } from 'polished';

const { studio } = themes;
export const main = {
  // actual grey128 - #474747
  slateGrey: '#5F6368',
  // actual grey140 - #666666
  calendarCondensedHeader: '#6d7883',
  // actual grey145 - #737373
  borderMidGrey: '#7e7e7e',
  // actual grey155 - #8c8c8c
  starDust: '#9B9B9B',
  bookingExpiredBorder: '#b0b8be',
  // actual grey175 - #bfbfbf
  anotherGrey: '#CCD6DE',
  // actual grey185 - #dadada
  borderGrey: '#d7d7d7',
  // actual grey190 - #e6e6e6
  lightGrey: '#eeefee',
  // actual grey195 - #f2f2f2
  veryLightGrey: '#f8f8f8',

  efGrey: '#EFEFEF',
  zircon: '#F3F7FF',
  serenade: '#FFF7E8',
  helloPink: (studio.palette?.secondary as SimplePaletteColorOptions).main,
  mandysPink: '#f5bfc2',
  classicRose: '#FFD3E9',
  pinkLace: '#FFF3F9',

  educationBlue: '#003C64',
  histogramDarkBlue: '#195074',
  tfBlue: '#017ACA',
  histogramLightBlue: '#19A8ED',
  plBookingBorder: '#18a6d3',
  plBookingBorderActive: '#009CCA',
  glBookingBorderActive: '#349ba2',
  glBookingBorder: '#41acb2',
  plBookingActiveBg: '#B7DFF0',
  glBookingActiveBg: '#c0e4e3',
  pelorous: '#e8f5f5',
  plBookingBg: '#e3f2f8',
  aquaSpring: '#EDF7FA',

  warning: '#D1334A',

  sun: '#faa81d',
  bookingAxisBorder: '#dfc427',
  salomie: '#fdd990',

  inkBlack: '#191919',

  greenSuccessCheck: '#339885',
  success: '#5DA335',
  teaGreen: '#d0edc0',

  transparent: 'transparent',

  warningBg: '#FFE1E1',

  white: '#FFFFFF',

  roleFocus: '#9AB3FA',
  paperLegal: '#FFFDE6',
};

export const role = {
  primary: main.tfBlue,
  focus: main.roleFocus,
};

export const paper = {
  legalPaper: main.paperLegal,
};

export const text = {
  border: rgba(main.inkBlack, 0.4),
  default: main.inkBlack,
  grey: main.starDust,
  disabled: lighten(0.5, main.inkBlack),
  light: main.white,
  unfocused: rgba(main.inkBlack, 0.8),
  slateGrey: main.slateGrey,
};

export const border = {
  disabled: rgba(main.inkBlack, 0.1),
  interactive: `${text.default}`,
  untouched: rgba(main.inkBlack, 0.5),
  grey: main.borderGrey,
  midGrey: main.borderMidGrey,
};

export const shadow = {
  untouched: rgba(main.inkBlack, 0.1),
};

export const calendar = {
  gridline: main.borderGrey,
  pastLesson: main.veryLightGrey,
  condensedHeader: main.calendarCondensedHeader,
  datePickerSidebar: main.veryLightGrey,
};

/*
$color-popup: #e6ebef; // TODO: review this color?
$c-card--shadow: 0 2px 15px 0 rgba(25, 25, 25, 0.16);

$color-link: $color-primary !default;
$color-link-bg: #cbe0ff !default;
$color-link-bg-inv: $color-primary !default;
$color-dark-grey: #474747;
$color-ui-accent: $color-primary !default; // used for interactive form colours, e.g. focus outline

// borders
$color-border-interactive: $color-text;
$color-border-interactive--light: $color-text--light;
*/
