import { Moment } from 'moment-timezone';

import { AcrForm } from 'model/AcrCommon';

export enum AllSkills {
  PRIVATE_LESSON_20 = 'private_lesson_20',
  PRIVATE_LESSON_40 = 'private_lesson_40',
  GROUP_LESSON = 'group_lesson',
  ENGLISH_FLUENCY_TEST = 'english_fluency_test',
  PERSONAL_VERBAL_ASSESSMENT = 'personal_verbal_assessment',
  BUSINESS_ENGLISH = 'business_english',
  VOCABULARY_CLINIC = 'vocabulary_clinic',
  PRONUNCIATION_CLINIC = 'pronunciation_clinic',
  GRAMMAR_CLINIC = 'grammar_clinic',
  HULT_EF = 'hult_ef',
  HULT_EF_VIP = 'hult_ef_vip',
  BEGINNER_WRITING_CORRECTIONS = 'beginner_writing_corrections',
  ELEMENTARY_WRITING_CORRECTIONS = 'elementary_writing_corrections',
  WRITING_CORRECTIONS = 'writing_corrections',
  HYPERCLASS_40 = 'hyperclass_40',
  CHINA_ENABLED = 'china_enabled',
}

export interface InstructionLangauge {
  isoCode: string;
  title: string;
}

export const AllSkillsLabels: Record<AllSkills, string> = {
  [AllSkills.PRIVATE_LESSON_20]: 'Private lesson 20',
  [AllSkills.PRIVATE_LESSON_40]: 'Private lesson 40',
  [AllSkills.GROUP_LESSON]: 'Group lesson',
  [AllSkills.ENGLISH_FLUENCY_TEST]: 'English fluency test',
  [AllSkills.PERSONAL_VERBAL_ASSESSMENT]: 'Personal verbal assessment',
  [AllSkills.BUSINESS_ENGLISH]: 'Business english',
  [AllSkills.VOCABULARY_CLINIC]: 'Vacabulary clinic',
  [AllSkills.PRONUNCIATION_CLINIC]: 'Pronunciation clinic',
  [AllSkills.GRAMMAR_CLINIC]: 'Grammar clinic',
  [AllSkills.HULT_EF]: 'Hult EF',
  [AllSkills.HULT_EF_VIP]: 'Hult EF VIP',
  [AllSkills.BEGINNER_WRITING_CORRECTIONS]: 'Beginner writing corrections',
  [AllSkills.ELEMENTARY_WRITING_CORRECTIONS]: 'Elementary writing corrections',
  [AllSkills.WRITING_CORRECTIONS]: 'Writing corrections',
  [AllSkills.HYPERCLASS_40]: 'HyperClass',
  [AllSkills.CHINA_ENABLED]: 'China Enabled',
};

export interface ITeacherRequirements {
  skills: AllSkills[];
  instructionLanguages: string[];
  departmentIds: string[];
}

export interface IEVCClassEntryRule {
  open: number;
  firstEntry: number;
}

export enum EVCLayoutCode {
  PL = 'adults_pl',
  PLV2 = 'adults_pl_v2',
  GL = 'adults_gl',
  GLV2 = 'adults_gl_v2',
  SPACES = 'adults_spaces',
  PL_SPACES = 'adults_pl_spaces',
  PLSS = 'adults_pl_ss',
  GLSS = 'adults_gl_ss',
  GL_BREAKOUT_ROOMS = 'gl_breakout_rooms',
  HC_SPACES = 'hyperclass_pl_spaces',
}

export enum EVCLocation {
  NV = 'NV',
  SH = 'SH',
}

export interface IEVCClassroomDef {
  teacher: IEVCClassEntryRule;
  student: IEVCClassEntryRule;
  closeDelay: number;
  location: EVCLocation;
  layout: EVCLayoutCode;
}

export enum ClassroomType {
  NONE = 'none',
  EVC = 'evc',
}

export enum LessonStatusAlgorithm {
  NONE = 'none',
  ASOT_PL20 = 'asot_pl20',
  ASOT_PL40 = 'asot_pl40',
  ASOT_GL45 = 'asot_gl45',
  EFPRO = 'efpro',
  HULT_SL40 = 'hult_sl40',
  HULT_SL80 = 'hult_sl80',
  HULT_SL120 = 'hult_sl120',
}

export interface IClassroomDef {
  classroomType: ClassroomType;
  evc?: IEVCClassroomDef;
}

export interface IContentDef {
  evc?: IEVCContentDef;
  required?: boolean;
}

export interface IEVCContentDef {
  customContent: boolean;
  lessonType: EVCContentLessonType;
}

export enum EVCContentLessonType {
  Private = 'pl',
  Group = 'gl',
}

export type tLessonType = keyof typeof EVCContentLessonType;

export const EVCContentLessonTypeLessonMapping: Record<EVCContentLessonType, tLessonType> = {
  [EVCContentLessonType.Private]: 'Private',
  [EVCContentLessonType.Group]: 'Group',
};

export interface IAcrConfig {
  acrForm?: AcrForm;
  lateSubmitDelay?: number;
}

export interface IOptions {
  name: string;
  value: string;
}

export interface IMeta {
  page: {
    totalPages: number;
    totalItems: number;
  };
}

export interface IResponse<T> {
  data: T[];
  meta: IMeta;
}

export interface IRange {
  start: Moment;
  end: Moment;
}

export interface Department {
  id: string;
  name: string;
  center: string;
}

export interface DepartmentWithSchool extends Department {
  school: School;
}

export interface School {
  id: string;
  name: string;
}

export interface AutomationsConfig {
  cancelUnassigned: CancelUnassignedAutomationConfig;
  assignUnassigned: AssignUnassignedAutomationConfig;
}

export interface CancelUnassignedAutomationConfig {
  enabled: boolean;
  bookingStartLtDelay?: number;
}

export interface AssignUnassignedAutomationConfig {
  enabled: boolean;
  bookingStartGteDelay?: number;
}

export const LessonBookingType = {
  Private: 'pl',
  Group: 'gl',
  Scheduled: 'sl',
} as const;

export interface IPrismConfig {
  lessonType?: PrismLessonType;
  courseCode?: PrismCourseCode;
}

export const PrismLessonTypes = [
  'group_lesson',
  'private_lesson',
  'checkpoint',
  'schedule_group_lesson',
  'schedule_private_lesson',
  'face_to_face_group_lessons',
  'face_to_face_private_lessons',
] as const;
export type PrismLessonType = (typeof PrismLessonTypes)[number];

export const PrismCourseCodes = [
  'N/A',
  'GE',
  'GENERAL_GERMAN',
  'GENERAL_FRENCH',
  'GENERAL_SPANISH',
  'GENERAL_SWEDISH',
  'GENERAL_PORTUGUESE',
] as const;
export type PrismCourseCode = (typeof PrismCourseCodes)[number];

export type ILessonBookingType = (typeof LessonBookingType)[keyof typeof LessonBookingType];

export interface IGLMatchmakingConfig {
  teacherEntryDelay?: number;
  studentEntryDelay?: number;
  newLessonMatchDelay?: number;
  closeDelay?: number;
  maxStudents?: number;
  holdSeatDuration?: number;
}

export interface Skill {
  id: string;
  code: string;
  description: string;
  state: string;
}
