import useSWRImmutable from 'swr/immutable';

import { DropdownDataProps } from 'components/MultiselectDropdown';
import { InstructionLangauge } from 'model/misc';
import { EvcProxyTunnel, ITeacher, TeacherPatch } from 'model/profiles';
import makeRequest from 'network/helpers/makeRequest';

export const updateTeacherProfile = (teacherId: string, tProfile: TeacherPatch): Promise<ITeacher> =>
  makeRequest<ITeacher>({
    pathname: `teachers/${teacherId}`,
    method: 'PATCH',
    body: JSON.stringify(tProfile),
  });

const getTeacherProfileUrl = (teacherId: string) => `teachers/${teacherId}`;

export const fetchTeacherProfile = (teacherId: string): Promise<ITeacher> =>
  makeRequest<ITeacher>({
    pathname: getTeacherProfileUrl(teacherId),
  });

export const useEvcProxyList = () =>
  useSWRImmutable('evc/tunnels', () =>
    makeRequest<{ data: EvcProxyTunnel[] }>({ pathname: 'evc/tunnels' }).then((response) => response.data),
  );

export const useInstructionLanguages = () =>
  useSWRImmutable('v1/languages', () =>
    makeRequest<{ data: InstructionLangauge[] }>({
      pathname: 'languages',
    }).then((response) => response.data),
  );

export const useLanguageOptions = (): DropdownDataProps[] => {
  const { data: languageOptions } = useInstructionLanguages();

  return languageOptions?.map((option) => ({ name: option.title, value: option.isoCode })) || [];
};
