import { HttpResponse, graphql, http } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings`, () => HttpResponse.json({}, { status: 500 })),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/acrs`, () => HttpResponse.json({}, { status: 500 })),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-3/class-entry-info`, async () =>
    HttpResponse.json({}, { status: 500 }),
  ),

  graphql.query('Announcements', () => HttpResponse.json({}, { status: 500 })),
];
