import React, { Fragment } from 'react';

import Avatar from 'components/Avatar';
import { DASH } from 'config/constants';
import { allPersonalInterestsLabels, PersonalInterest, TeacherStatus } from 'config/teacherProfiles';
import { EVCLocation } from 'model/misc';
import { GqlTeacherProfile } from 'model/profiles';
import { useEvcTunnelForTeacher } from 'network/Classroom';
import useSkillsMap from 'utils/hooks/useSkillOptions';
import { getEnglishFullLangName } from 'utils/miscellaneous';

import {
  ChipTypeA,
  ChipTypeB,
  ContentWrapper,
  EditProfileLink,
  GreenCircle,
  Hr,
  Label,
  ListContainer,
  ProfileName,
  RedCircle,
  Text,
  YellowCircle,
} from './styledComponents';

interface ViewContentProps {
  profile: GqlTeacherProfile;
  switchToEditMode(): void;
}

export const ViewContent: React.FC<ViewContentProps> = ({ profile, switchToEditMode }) => {
  const { data: evcProxy } = useEvcTunnelForTeacher(profile.id, EVCLocation.SH);
  const skills = useSkillsMap();
  return (
    <ContentWrapper>
      <RenderBasics profile={profile} EditButton={<EditProfileLink onClick={switchToEditMode}>Edit</EditProfileLink>} />
      <Hr />
      <RenderLabeledValue label="Email" value={profile.email} />
      <RenderList
        label="Personal Interests"
        list={profile.personalInterests}
        transformer={(el) => allPersonalInterestsLabels[el as PersonalInterest]}
      />
      <RenderList
        label="Skills"
        list={profile.teacherSkills?.map((skill) => skill.id) || []}
        transformer={(el) => skills.get(el)?.description || ''}
      />
      <RenderList label="Spoken Languages" list={profile.instructionLanguages} transformer={getEnglishFullLangName} />
      <RenderList label="EVC China Proxy" list={evcProxy?.description ? [evcProxy.description] : []} />
    </ContentWrapper>
  );
};

interface RenderBasicsProps {
  profile: GqlTeacherProfile;
  EditButton?: JSX.Element;
}

export const RenderBasics = ({ profile, EditButton }: RenderBasicsProps) => (
  <div style={{ display: 'flex' }}>
    <Avatar
      profilePicture={profile?.profilePicturePath}
      initials={`${profile?.firstName.charAt(0)}${profile?.lastName.charAt(0)}`.toLocaleUpperCase() || ''}
      size={80}
      fontSize={22}
      fontColor="#191919"
      avatar="profile"
    />
    <div style={{ width: 400, marginLeft: 16 }}>
      <ProfileName>
        {profile?.firstName ?? DASH} {profile?.lastName ?? DASH}
        {EditButton}
      </ProfileName>
      <div style={{ marginTop: 5, transform: 'translateY(4px)' }}>
        {profile.status === TeacherStatus.ACTIVE ? (
          <Fragment>
            <GreenCircle />
            Active
          </Fragment>
        ) : profile.status === TeacherStatus.PENDING_DOWNGRADE ? (
          <Fragment>
            <YellowCircle />
            Pending deactivation
          </Fragment>
        ) : (
          <Fragment>
            <RedCircle />
            Deactivated
          </Fragment>
        )}
      </div>
      <div style={{ display: 'flex', marginTop: 16 }}>
        <ChipTypeA>{profile?.department?.name || DASH}</ChipTypeA>
      </div>
    </div>
  </div>
);

export const RenderList: React.FC<{
  label: string;
  list: string[];
  transformer?: (arg: string) => string;
}> = ({ label, list = [], transformer }) => (
  <Fragment>
    <Label>{label}</Label>
    <ListContainer>
      {list?.length === 0 ? (
        <Text>{DASH}</Text>
      ) : (
        list.map((item) => <ChipTypeB key={item}>{transformer ? transformer(item) : item}</ChipTypeB>)
      )}
    </ListContainer>
  </Fragment>
);

export const RenderLabeledValue: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <Fragment>
    <Label>{label}</Label>
    <Text>{value}</Text>
  </Fragment>
);
