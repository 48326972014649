import { GraphQLHandler, HttpHandler } from 'msw';

import { handlers as AcrMockHandler } from './acr';
import { handlers as classPrepHandlers } from './classPrep';
import { handlers as CommonHandlers } from './common';
import { handlers as DashboardHandlers } from './dashboard';
import { handlers as scheduledLessonHandlers } from './scheduledLessons';
import { handlers as StudentMockHandler } from './students';
import { handlers as TeacherMockHandler } from './teachers';

export type HandlersType = Array<HttpHandler | GraphQLHandler>;
export interface HandlersByScenario {
  [key: string]: Array<HttpHandler | GraphQLHandler>;
}

const allHandlers: HandlersByScenario[] = [
  AcrMockHandler,
  scheduledLessonHandlers,
  classPrepHandlers,
  StudentMockHandler,
  TeacherMockHandler,
  DashboardHandlers,
];

export const getScenarioHandlers = (scenario: string): Array<HttpHandler | GraphQLHandler> => {
  const scenarioHandlers: Array<HttpHandler | GraphQLHandler> = [...CommonHandlers];

  allHandlers.forEach((pageHandlers) => {
    if (scenario === '' || !(scenario in pageHandlers)) {
      scenarioHandlers.push(...pageHandlers.default);
    } else {
      scenarioHandlers.push(...pageHandlers[scenario]);
    }
  });

  return scenarioHandlers;
};
