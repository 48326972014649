import acrs from 'cypress/fixtures/teacherfirst/dashboard/empty/acr-draft.json';
import Announcements from 'cypress/fixtures/teacherfirst/dashboard/empty/announcements.json';
import bookingsConfirmed from 'cypress/fixtures/teacherfirst/dashboard/empty/bookings-confirmed.json';
import { HttpResponse, graphql, http } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings`, ({ request }) => {
    const url = new URL(request.url);
    const teacherID = url.searchParams.get('teacherId');

    if (!teacherID) {
      return new HttpResponse(null, { status: 404 });
    }

    return HttpResponse.json(bookingsConfirmed);
  }),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/acrs`, ({ request }) => {
    const url = new URL(request.url);
    const teacherID = url.searchParams.get('teacherId');

    if (!teacherID) {
      return new HttpResponse(null, { status: 404 });
    }

    return HttpResponse.json(acrs);
  }),

  graphql.query('Announcements', () => HttpResponse.json(Announcements)),
];
