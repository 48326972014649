import { gql } from '@apollo/client';

export interface IGQLTeachersResponse<T> {
  teachers: {
    edges: T[];
    pageInfo: {
      pages: number;
      total: number;
    };
  };
}

export interface IGQLTeacherResponse<T> {
  teacher: T;
}

export const TEACHER_SEARCH_GQL = gql`
  query Teachers($filter: TeachersFilter, $pagination: Pagination) {
    teachers(filter: $filter, pagination: $pagination) {
      pageInfo {
        pages
        total
      }
      edges {
        id
        firstName
        lastName
        email
        personalInterests
        instructionLanguages
        emailSubscribed
        role
        profilePicturePath
        teacherSkills {
          id
          code
          description
        }
        department {
          id
          name
          school {
            id
            name
          }
        }
        path
      }
    }
  }
`;

export const TEACHER_GQL = gql`
  query Teacher($id: ID!) {
    teacher(id: $id) {
      id
      firstName
      lastName
      center
      email
      personalInterests
      profilePicturePath
      instructionLanguages
      emailSubscribed
      role
      skills
      teacherSkills {
        id
        code
        description
      }
      status
      path
      role
      department {
        id
        name
        school {
          id
          name
        }
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
