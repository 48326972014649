import { handlers as emptyHandlers } from './empty';
import { handlers as errorHandlers } from './error';
import { handlers as fullHandlers } from './full';
import { handlers as genericHandlers } from './generic';
import { handlers as loadingHandlers } from './loading';

export const handlers = {
  default: [...genericHandlers, ...fullHandlers],
  full: [...genericHandlers, ...fullHandlers],
  empty: [...genericHandlers, ...emptyHandlers],
  error: [...errorHandlers],
  loading: [...loadingHandlers],
};
