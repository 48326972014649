import { IconUnion } from 'components/Icon';

export const iconMap: Record<string, IconUnion> = {
  food: 'food',
  reading: 'reading',
  music: 'song',
  technology: 'tablet',
  fashion: 'sunglasses',
  visual_arts: 'art',
  fitness: 'fitness',
  gaming: 'gamePad',
  outdoors: 'sun',
  language: 'language',
  photography: 'camera',
  gardening: 'flower',
  sport: 'sports',
  movies: 'film',
  performing_arts: 'ticketStar',
  traveling: 'compass',
  business: 'business',
  law: 'law',
  medicine: 'medicine',
  finance: 'finance',
  marketing: 'marketing',
  pharmaceuticals: 'pharmaceuticals',
  consultancy: 'consultancy',
  aviation: 'aviation',
  emergency_services: 'emergencyServices',
  it: 'it',
  sales: 'sales',
  deib: 'deib',
};

export enum PersonalInterest {
  Food = 'food',
  Reading = 'reading',
  Music = 'music',
  Technology = 'technology',
  Fashion = 'fashion',
  Visual_Arts = 'visual_arts',
  Fitness = 'fitness',
  Gaming = 'gaming',
  Outdoors = 'outdoors',
  Language = 'language',
  Photography = 'photography',
  Gardening = 'gardening',
  Sport = 'sport',
  Movies = 'movies',
  Performing_Arts = 'performing_arts',
  Traveling = 'traveling',
  Business = 'business',
  Law = 'law',
  Medicine = 'medicine',
  Finance = 'finance',
  Marketing = 'marketing',
  Pharmaceuticals = 'pharmaceuticals',
  Consultancy = 'consultancy',
  Aviation = 'aviation',
  Emergency_Services = 'emergency_services',
  IT = 'it',
  Sales = 'sales',
  DEIB = 'deib',
}

export const personalInterestsIcons: Record<PersonalInterest, string> = {
  [PersonalInterest.Food]: iconMap.food,
  [PersonalInterest.Reading]: iconMap.reading,
  [PersonalInterest.Music]: iconMap.music,
  [PersonalInterest.Technology]: iconMap.technology,
  [PersonalInterest.Fashion]: iconMap.fashion,
  [PersonalInterest.Visual_Arts]: iconMap.visual_arts,
  [PersonalInterest.Fitness]: iconMap.fitness,
  [PersonalInterest.Gaming]: iconMap.gaming,
  [PersonalInterest.Outdoors]: iconMap.outdoors,
  [PersonalInterest.Language]: iconMap.language,
  [PersonalInterest.Photography]: iconMap.photography,
  [PersonalInterest.Gardening]: iconMap.gardening,
  [PersonalInterest.Sport]: iconMap.sport,
  [PersonalInterest.Movies]: iconMap.movies,
  [PersonalInterest.Performing_Arts]: iconMap.performing_arts,
  [PersonalInterest.Traveling]: iconMap.traveling,
  [PersonalInterest.Business]: iconMap.business,
  [PersonalInterest.Law]: iconMap.law,
  [PersonalInterest.Medicine]: iconMap.medicine,
  [PersonalInterest.Finance]: iconMap.finance,
  [PersonalInterest.Marketing]: iconMap.marketing,
  [PersonalInterest.Pharmaceuticals]: iconMap.pharmaceuticals,
  [PersonalInterest.Consultancy]: iconMap.consultancy,
  [PersonalInterest.Aviation]: iconMap.aviation,
  [PersonalInterest.Emergency_Services]: iconMap.emergency_services,
  [PersonalInterest.IT]: iconMap.it,
  [PersonalInterest.Sales]: iconMap.sales,
  [PersonalInterest.DEIB]: iconMap.deib,
};

export const allPersonalInterestsLabels: Record<PersonalInterest, string> = {
  [PersonalInterest.Food]: 'Food',
  [PersonalInterest.Reading]: 'Reading',
  [PersonalInterest.Music]: 'Music',
  [PersonalInterest.Technology]: 'Technology',
  [PersonalInterest.Fashion]: 'Fashion',
  [PersonalInterest.Visual_Arts]: 'Visual Arts',
  [PersonalInterest.Fitness]: 'Fitness',
  [PersonalInterest.Gaming]: 'Gaming',
  [PersonalInterest.Outdoors]: 'Outdoors',
  [PersonalInterest.Language]: 'Language',
  [PersonalInterest.Photography]: 'Photography',
  [PersonalInterest.Gardening]: 'Gardening',
  [PersonalInterest.Sport]: 'Sport',
  [PersonalInterest.Movies]: 'Movies',
  [PersonalInterest.Performing_Arts]: 'Performing Arts',
  [PersonalInterest.Traveling]: 'Traveling',
  [PersonalInterest.Business]: 'Business',
  [PersonalInterest.Law]: 'Law',
  [PersonalInterest.Medicine]: 'Medicine',
  [PersonalInterest.Finance]: 'Finance',
  [PersonalInterest.Marketing]: 'Marketing',
  [PersonalInterest.Pharmaceuticals]: 'Pharmaceuticals',
  [PersonalInterest.Consultancy]: 'Consultancy',
  [PersonalInterest.Aviation]: 'Aviation',
  [PersonalInterest.Emergency_Services]: 'Emergency Services',
  [PersonalInterest.IT]: 'IT',
  [PersonalInterest.Sales]: 'Sales',
  [PersonalInterest.DEIB]: 'DEIB',
};

export const allPersonalInterests = Object.keys(allPersonalInterestsLabels) as PersonalInterest[];

export enum TeacherRole {
  THIRD_PARTY = 'third_party',
  INDEPENDENT_CONTRACTOR = 'independent_contractor',
  STAFF = 'staff',
  FLEXIBLE_STAFF = 'flexible_staff',
}

export enum TeacherStatus {
  ACTIVE = 'active',
  PENDING_DOWNGRADE = 'pending_downgrade',
  TERMINATED = 'terminated',
}

export const teacherStatusLabels: Record<TeacherStatus, string> = {
  [TeacherStatus.ACTIVE]: 'Active',
  [TeacherStatus.PENDING_DOWNGRADE]: 'Pending deactivation',
  [TeacherStatus.TERMINATED]: 'Deactivated',
};

export const teacherStatuses = Object.keys(teacherStatusLabels) as TeacherStatus[];

export const teacherStatusOptions = (Object.keys(teacherStatusLabels) as TeacherStatus[]).map((value) => ({
  name: teacherStatusLabels[value],
  value,
}));

export const allTeacherRolesLabels: Record<TeacherRole, string> = {
  [TeacherRole.THIRD_PARTY]: 'Third party',
  [TeacherRole.INDEPENDENT_CONTRACTOR]: 'Independent contractor',
  [TeacherRole.STAFF]: 'Staff',
  [TeacherRole.FLEXIBLE_STAFF]: 'Flexible staff',
};

export const allTeacherRoles = Object.keys(allTeacherRolesLabels) as TeacherRole[];

// ====== derived dropdown options

export const interestsOptions = allPersonalInterests.map((value) => ({
  name: allPersonalInterestsLabels[value],
  value,
}));
