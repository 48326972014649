import { HttpResponse, graphql, http, delay } from 'msw';

import env from 'config/env';

export const handlers = [
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings`, async () => {
    await delay('infinite');

    return HttpResponse.json({});
  }),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/acrs`, async () => {
    await delay('infinite');

    return HttpResponse.json({});
  }),
  http.get(`${env.TF_SVCGATEWAY_URL}/v1/bookings/booking-id-3/class-entry-info`, async () => {
    await delay('infinite');
    return HttpResponse.json({});
  }),

  graphql.query('Announcements', async () => {
    await delay('infinite');
    return HttpResponse.json({});
  }),
];
