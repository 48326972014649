import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { GqlTeacherProfile } from 'model/profiles';

import Profile from '../Profile';
import Settings from '../Settings';
import { DrawerTypes } from './DrawerTypes';
import { DrawerWrapper, BackgroundOverlay, Drawer } from './styledComponents';

interface SideBarDrawerProps {
  teacher: GqlTeacherProfile;
  school?: string;
  drawer?: DrawerTypes | null;
  showOverlay?: boolean;
  closeDrawer: () => void;
}

const SideBarDrawer = ({ teacher, school, drawer, closeDrawer, showOverlay }: SideBarDrawerProps) => {
  const hasDrawer = drawer !== null && drawer !== undefined;
  const showDrawer = hasDrawer || showOverlay;
  const nodeRef1 = useRef(null);
  const nodeRef2 = useRef(null);
  const nodeRef3 = useRef(null);

  return (
    <DrawerWrapper style={{ width: showDrawer ? '100vw' : 0 }}>
      <CSSTransition nodeRef={nodeRef1} unmountOnExit in={showDrawer} timeout={300} classNames="fade" appear>
        <BackgroundOverlay ref={nodeRef1} onClick={closeDrawer} />
      </CSSTransition>

      <CSSTransition
        nodeRef={nodeRef2}
        unmountOnExit
        timeout={200}
        classNames="slide"
        appear
        in={drawer === DrawerTypes.PROFILE}
      >
        <Drawer ref={nodeRef2}>
          <Profile teacher={teacher} school={school} isOpen setIsOpen={closeDrawer} />
        </Drawer>
      </CSSTransition>

      <CSSTransition
        nodeRef={nodeRef3}
        unmountOnExit
        timeout={200}
        classNames="slide"
        appear
        in={drawer === DrawerTypes.SETTINGS}
      >
        <Drawer ref={nodeRef3}>
          <Settings teacherId={teacher.id} close={closeDrawer} />
        </Drawer>
      </CSSTransition>
    </DrawerWrapper>
  );
};

export default SideBarDrawer;
