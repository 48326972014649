import { useMemo } from 'react';

import { useSchoolContext } from 'context/School/School';
import { Skill } from 'model/misc';
import { useSkills } from 'network/graphql/Skills';

const useSkillsMap = () => {
  const { schoolId } = useSchoolContext();
  const { skills } = useSkills({ schoolId });

  const skillsMap = useMemo(() => {
    const skillsMapToReturn = new Map<string, Skill>();

    skills?.forEach((skill) => {
      skillsMapToReturn.set(skill.id, skill);
    });

    return skillsMapToReturn;
  }, [skills]);

  return skillsMap;
};

export default useSkillsMap;
