import moment from 'moment-timezone';

export const DASH = String.fromCharCode(8211);
export const X = '✕';
export const LONG_DATE_FORMAT = 'DD MMM yyyy\tddd\tHH:mm';
export const SHORT_DATE_FORMAT = 'DD/MM/YY';
export const DATE_TIME_FORMAT = 'DD/MM/YY HH:mm';

export const TZ_OFFSET = moment().format(' (UTCZ)');
export const TZ_NAME = new Date().toTimeString().split(/\)|\(/g)?.[1];
export const LOCALE_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const TZ_OFFSET_LONG = (TZ_NAME || LOCALE_TZ) + TZ_OFFSET;

export const REGEX_EMAIL =
  /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;

export const BLANK_ID = '00000000-0000-0000-0000-000000000000';
export const REGEX_ID = new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/);

// such declaration is necessary to make DefinePlugin work
declare const GITHUB_RUN_NUMBER: string;
const _GITHUB_RUN_NUMBER = GITHUB_RUN_NUMBER;
export { _GITHUB_RUN_NUMBER as GITHUB_RUN_NUMBER };

declare const MOCKED: string | number;
const _MOCKED = typeof MOCKED !== 'undefined' ? MOCKED : 0;
export { _MOCKED as MOCKED };
