import useSWR from 'swr';

import { IUser } from 'model/profiles';
import { UserSearchResponse } from 'model/User';
import makeRequest from 'network/helpers/makeRequest';

const parseProfile = (serverProfile: IUser) => {
  if (!serverProfile.isActive) {
    // TODO: handle inactive user
    // TODO: validate teacher
  }

  const fullName = `${serverProfile.firstName.trim?.()} ${
    // mind the space
    serverProfile.lastName.trim?.()
  }`;

  return {
    ...serverProfile,
    fullName,
    fullNameLowerCase: fullName.toLocaleLowerCase(),
    initials: `${serverProfile.firstName.charAt?.(0)?.trim?.()}${serverProfile.lastName?.charAt(0)?.trim?.()}`,
  };
};

export const fetchUserProfile = (): Promise<IUser> =>
  makeRequest<IUser>({
    pathname: '/users/me',
  }).then(parseProfile);

export const useMe = (polling?: number) => {
  const { data, error } = useSWR<IUser>('/users/me', fetchUserProfile, {
    refreshInterval: polling || 0,
  });
  return {
    user: data,
    isLoading: !error && !data,
    error,
  };
};

export const getUserByEmail = async (email: string) =>
  makeRequest<UserSearchResponse>({
    method: 'GET',
    pathname: 'users',
    searchParams: { email },
  });
