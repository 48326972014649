import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';

import TimezoneInput from 'components/AutoComplete/TimezoneInput';
import { PrimaryButton } from 'components/Button';
import ClickToCopyID from 'components/ClickToCopyID';
import { Feature } from 'components/FeatureFlag';
import { AreYouSureModal } from 'components/Modal';
import env from 'config/env';
import { useTeacherContext } from 'context/Profile';
import { ICalendarSettings } from 'model/Settings';
import { Timezone, getInitialTimezone } from 'model/Timezone';
import { postTeacherWebcal, updateTeacherCal, useTeacherWebCal } from 'network/Settings';
import Events from 'utils/analytics/events';
import { logEvent } from 'utils/analytics/helpers';
import { useTeacherPermissions } from 'utils/hooks/usePermissions';

import * as S from './settings-calendar.styled';

interface Props {
  settings: ICalendarSettings;
  storeSettings: (settings: ICalendarSettings) => void;
}

const SettingsCalendar = ({ settings, storeSettings }: Props) => {
  const teacher = useTeacherContext().selectedTeacher;

  const [generateCheck, setGenerateCheck] = useState<boolean>(false);
  const { permissions } = useTeacherPermissions();
  const { data: webcal, mutate } = useTeacherWebCal(teacher.id);

  const updateField = useCallback(
    (partial: Partial<ICalendarSettings>) => {
      storeSettings({ ...settings, ...partial });
    },
    [settings, storeSettings],
  );

  const updateUrl = useCallback(() => {
    if (webcal && webcal.id) {
      updateTeacherCal(webcal.id).then(mutate);
    } else {
      postTeacherWebcal(teacher.id).then(mutate);
    }
  }, [mutate, teacher.id, webcal]);

  const generateUrl = useCallback(() => {
    if (webcal) {
      setGenerateCheck(true);
    } else {
      updateUrl();
    }
  }, [webcal, updateUrl]);

  const currentTimezone = useMemo(getInitialTimezone, []);

  return (
    <S.SettingsCalendarStyles>
      <section>
        <div>
          <S.Label>Default Time zone</S.Label>
          <TimezoneInput width={400} disabled value={currentTimezone} onChange={() => null} />
        </div>
        <div>
          <S.Label>Secondary Time zone</S.Label>
          <S.CheckBoxStyle>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={settings.useSecondaryTimezone}
                  onChange={(e) => {
                    logEvent(Events.SETTINGS_SECONDARY_TIMEZONE_CHECK, {
                      Enabled: !settings.useSecondaryTimezone,
                    });
                    updateField({ useSecondaryTimezone: e.target.checked });
                  }}
                />
              }
              label="Display secondary time zone"
            />
          </S.CheckBoxStyle>
          <TimezoneInput
            width={400}
            disabled={!settings.useSecondaryTimezone}
            value={settings.secondaryTimezone}
            onChange={(option: Timezone) => {
              logEvent(Events.SETTINGS_SECONDARY_TIMEZONE, {
                Timezone: option,
              });
              updateField({ secondaryTimezone: option });
            }}
          />
        </div>
        <div>
          <S.Label>Share calendar</S.Label>
          <div style={{ fontSize: 14, lineHeight: '22px', marginBottom: 12 }}>
            Use this address to access your Teacher First calendar from other applications.
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <S.CalendarUrl onClick={() => logEvent(Events.WEBCAL, { 'Teacher ID': teacher.id })}>
              {webcal ? (
                <ClickToCopyID id={`${env.TF_SVCGATEWAY_URL}/v1/webcals/${webcal.secret}/ics`} />
              ) : (
                <div style={{ color: 'rgba(0,0,0,0.48)' }}>Generate new URL</div>
              )}
            </S.CalendarUrl>
            <PrimaryButton style={{ marginLeft: 8, height: 32, width: 112 }} onClick={() => generateUrl()}>
              {webcal && webcal.id ? 'Update' : 'Generate'}
            </PrimaryButton>
          </div>
        </div>
        <Feature flag="reserve-timeslots">
          {permissions.teacher.EditReserveTimeslotSettings() && (
            <div>
              <S.Label>Reserved time slots</S.Label>
              <S.CheckBoxStyle>
                <S.ReserveTimeslotToggleStyled teacherId={teacher?.id} />
              </S.CheckBoxStyle>
            </div>
          )}
        </Feature>
      </section>
      {generateCheck && (
        <AreYouSureModal
          isOpen={generateCheck}
          onClose={() => setGenerateCheck(false)}
          callback={() => updateUrl()}
          message="Any calendars subscribed with the old URL will no longer get updated."
          height={250}
        />
      )}
    </S.SettingsCalendarStyles>
  );
};

export default SettingsCalendar;
