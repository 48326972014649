import { useState, useEffect, useCallback } from 'react';

export const useField = <T extends unknown>(initialValue: T, validator?: (arg?: T) => boolean, defaultValue?: T) => {
  const [value, setValue] = useState<T>(initialValue);
  const [isPristine, setisPristine] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (validator) {
      setIsError(!validator(initialValue));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = useCallback(
    (newValue: T) => {
      const nextValue = newValue || defaultValue;
      if (validator) {
        setIsError(!validator(nextValue));
      }
      setisPristine(false);
      setValue(nextValue!);
    },
    [defaultValue, validator],
  );

  return {
    value: value || defaultValue,
    isPristine,
    onChange,
    isError,
  };
};
