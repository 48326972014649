import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Loading from 'components/Loading';
import { AppLayout } from 'components/sharedStyles';
import {
  STUDENTS_LOBBY_PATH,
  SHARED_ACR_PATH,
  SHARED_CLASS_PREPARATION_PATH,
  TF_LESSON_HISTORY_OVERVIEW,
  TF_DASHBOARD_ROUTE,
  TF_CALENDAR_EDIT_ROUTE,
  TF_CALENDAR_ROUTE,
  TF_HOME_PATH,
  TF_TEACHER_LOBBY_PATH,
  UNAUTHORIZED_USER_PATH,
  TF_STUDENT_GROUPS_PATH,
} from 'config/internalUrlPaths';
import { useTeacherContext, useUserContext } from 'context/Profile';
import { initAmplitude } from 'utils/analytics';
import Events from 'utils/analytics/events';
import { logEvent } from 'utils/analytics/helpers';
import { useTeacherPermissions } from 'utils/hooks/usePermissions';
import useWindowSize from 'utils/hooks/useWindowSize';

import { LobbyComponent, StudentLobbyComponent } from './views/Lobby/Lobby';
import Sidebar from './views/SideBar';

const LazyCalendarView = React.lazy(() => import('./views/Calendar/calendar-view.component'));
const LazyCalendarEdit = React.lazy(() => import('./views/Calendar/calendar-edit.component'));
const LazyDashboard = React.lazy(() => import('./views/Dashboard'));
const LazyAcr = React.lazy(() => import('./views/Acr'));
const LazyLessonHistory = React.lazy(() => import('./views/LessonHistory'));
const LazyClassPreparation = React.lazy(() => import('./views/ClassPreparation'));
const LazyStudentGroups = React.lazy(() => import('./views/StudentGroups'));

const Routes = () => {
  const location = useLocation();
  const [isTrackingEventsInitialised, setTrackingEventsInitialised] = useState(false);

  const { user } = useUserContext();
  const teacher = useTeacherContext().selectedTeacher;
  const { permissions } = useTeacherPermissions();

  const { mobile } = useWindowSize();

  useEffect(
    function initAmplitudeAndDatadog() {
      if (user?.id && teacher?.id && !isTrackingEventsInitialised) {
        initAmplitude(
          user.id,
          window.location.origin,
          teacher?.department.school.name,
          teacher?.department.name,
          teacher.role,
        );
        logEvent(Events.LANDING_PAGE, { 'Landing Page': location.pathname });

        datadogRum.setUser({
          id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          roles: user.roles,
        });

        datadogLogs.setGlobalContextProperty('caller.id', user.id);
        datadogLogs.setGlobalContextProperty('caller.email', user.email);

        setTrackingEventsInitialised(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.id, teacher?.id, isTrackingEventsInitialised],
  );

  if (!user || !teacher) {
    return <Loading />;
  }

  if (!permissions.teacher.ViewTeachersPortal()) {
    return <Redirect to={UNAUTHORIZED_USER_PATH} />;
  }

  return (
    <AppLayout mobile={mobile}>
      <Sidebar />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path={TF_DASHBOARD_ROUTE} exact component={LazyDashboard} />

          <Route path={TF_CALENDAR_ROUTE} exact component={LazyCalendarView} />
          {permissions.teacher.EditAvailability() && (
            <Route path={TF_CALENDAR_EDIT_ROUTE} exact component={LazyCalendarEdit} />
          )}
          <Route path={SHARED_CLASS_PREPARATION_PATH} exact component={LazyClassPreparation} />
          <Route path={TF_LESSON_HISTORY_OVERVIEW} component={LazyLessonHistory} />
          <Route path={SHARED_ACR_PATH} exact component={LazyAcr} />
          <Route path={TF_TEACHER_LOBBY_PATH} exact component={LobbyComponent} />
          <Route path={STUDENTS_LOBBY_PATH} exact component={StudentLobbyComponent} />
          <Route path={TF_STUDENT_GROUPS_PATH} component={LazyStudentGroups} />
          <Route>
            <Redirect to={TF_HOME_PATH} />
          </Route>
        </Switch>
      </Suspense>
    </AppLayout>
  );
};

export default Routes;
