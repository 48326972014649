import { StyledEngineProvider, Theme, createTheme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from 'theme/index';

const Wrapper: React.FC = (props) => {
  const muiTheme = createTheme(theme as unknown as Theme);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>{React.Children.only(props.children)}</MuiThemeProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default Wrapper;
