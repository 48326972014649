import { Modal, styled } from '@mui/material';

export const ThemedModal = styled(Modal)({
  '&.MuiModal-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const ActionsButtonWrapper = styled('div')<{ justify?: string }>`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.justify || 'center'};
  align-items: center;
  margin: 0 0;
`;

export const ModalContentWrapper = styled('div')<{ responsive?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.responsive && 'margin-top: 64px;'}
`;

export const ValidationMessage = styled('span')`
  font-size: ${({ theme }) => theme.fontSize[12]};
  color: ${({ theme }) => theme.color.warning};
`;

export const Paper = styled('div')<{ isMobile: boolean; width: number; minWidth?: number }>(
  ({ isMobile, width, minWidth, theme }) => `
    min-width: ${isMobile || width <= minWidth! ? '100vw' : (minWidth || 430) + 'px'};
    min-height: ${isMobile ? `${window.innerHeight}px` : 0};
    max-height: ${isMobile ? `${window.innerHeight}px` : 'initial'};
    position: relative;
    overflow: auto;
    background-color: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[5]};
    border-radius: ${theme.form.input.borderRadius};
    outline: 0;
    padding: ${theme.spacing(2, 4, 5)};
  `,
);

export const IconContainer = styled('div')<{ mobile: boolean }>`
  position: absolute;
  right: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  justify-content: flex-end;
  z-index: 10;
  color: ${({ theme }) => theme.color.inkBlack};
`;

export const Title = styled('h4')`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize[20]};
  line-height: 2;
`;
