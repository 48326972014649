import { TextField } from '@mui/material';
import React from 'react';

import Icon from 'components/Icon';
import { timezoneOptionMap, timezoneOptionsSorted, Timezone } from 'model/Timezone';

import * as S from './autocomplete.styled';

interface Props {
  onChange: (option: Timezone) => void;
  value: Timezone;
  disabled?: boolean;
  width?: number;
}

const TimezoneAutoComplete = ({ onChange, value, disabled, width = 350 }: Props) => (
  <S.TimezoneAutocomplete
    value={value}
    disabled={disabled}
    key="autocomplete"
    disableClearable
    clearOnEscape
    clearOnBlur
    disablePortal
    id="timezone-select"
    style={{ width }}
    options={timezoneOptionsSorted}
    autoHighlight
    getOptionLabel={(tz) => timezoneOptionMap[tz]}
    onChange={(_, option) => {
      if (option) {
        onChange(option);
      }
    }}
    isOptionEqualToValue={(option) => option === value}
    renderInput={(params) => (
      <TextField
        placeholder={value}
        {...params}
        variant="outlined"
        inputProps={{
          ...params.inputProps,
          readOnly: disabled,
        }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <S.DropdownIconContainer
              disabled={disabled || false}
              onClick={!disabled ? params.inputProps?.onMouseDown : undefined}
            >
              <Icon icon="chevron" />
            </S.DropdownIconContainer>
          ),
        }}
      />
    )}
  />
);

export default TimezoneAutoComplete;
