import { gql, useQuery } from '@apollo/client';

import { Skill } from 'model/misc';

const SKILLS_GQL = gql`
  query Skills($filter: SkillFilter) {
    skills(filter: $filter) {
      edges {
        code
        description
        id
        state
        createdAt
      }
    }
  }
`;

export interface SkillFilter {
  schoolId?: string;
}

export const useSkills = (filter?: SkillFilter) => {
  const { data, ...rest } = useQuery<{ skills: { edges: Skill[] } }>(SKILLS_GQL, {
    variables: {
      filter,
    },
  });

  return {
    skills: data?.skills?.edges,
    ...rest,
  };
};
