import React, { Fragment, useContext } from 'react';

import { FeatureFlagEnum } from './enums';
import FeatureFlagContext from './Provider';

interface ConsumerProps {
  flag: FeatureFlagEnum;
  DefaultComponent?: React.ReactNode | null;
}

const Consumer: React.FC<ConsumerProps> = ({ flag, children, DefaultComponent = null }) => {
  const { featureFlags } = useContext(FeatureFlagContext);

  if (typeof children === 'function') {
    return children(featureFlags.includes(flag));
  }

  // This is put in place to allow for a default component to be displayed if the feature flag is not enabled
  // DefaultComponent should be set like this <Consumer flag={FeatureFlagEnum.FEATURE_FLAG_NAME} DefaultComponent={<Component />} />
  // if DefaultComponent <Consumer flag={FeatureFlagEnum.FEATURE_FLAG_NAME} DefaultComponent={Component} /> it won't fail but not recommended
  const displayDefault = typeof DefaultComponent === 'function' ? DefaultComponent() : DefaultComponent;
  return featureFlags.includes(flag) ? <Fragment>{children}</Fragment> : displayDefault;
};

export default Consumer;
